<template>
  <div>
    <!-- 顶部 -->
    <div class="page">
      <div class="header">
        <div class="header-logo">
          <!-- <img src="../../assets/images/tubiao/yezai_logo.png" alt="" /> -->
          <!-- <img src="../../assets/images/tubiao/yezai_logo_A.png" alt="" /> -->
          <img src="../../assets/images/tubiao/yezai_logo_B.png" alt="" />
        </div>
        <router-link class="header-btn" to="/login"> 登录 </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  position: fixed;
  background-color: #fd686eff;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  top: 0px;
  left: 0px;
  z-index: 9999;
  .header {
    min-width: 1200px;
    height: 80px;
    background-color: #fd686eff;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-logo {
      width: 136px;
    }
    .header-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 42px;
      border-radius: 6px;
      border: 1px solid #ffffff;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      background-color: #fb5057;
      border-radius: 6px;
      cursor: pointer;
      // a {
      color: #ffffff;
      font-size: 14px;
      // }
    }
  }
}
</style>