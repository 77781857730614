<template>
  <div class="header">
    <!-- <div class="page">
      <img src="../../assets/images/tubiao/yezai_logo.png" alt="" />
      <ul>
        <li :class="index == 0 ? 'active' : ''">
          <router-link to="/n/myyezai">我的也在</router-link>
        </li>
        <li :class="index == 1 ? 'active' : ''">
          <router-link to="/n/search">搜索</router-link>
        </li>
        <li>
          <p @click="tiao">直营门店</p>
        </li>
        <li :class="index == 2 ? 'active' : ''">
          <router-link to="/n/yezaiMember">也在会员</router-link>
        </li>
      </ul>
      <div class="h-btn">
        <div class="btn1" @mouseout="out(1)" @mouseover="over(1)">
          <img src="../../assets/images/index/xiaoxi.png" alt="" />
          <div class="btn-box1" ref="box1" v-show="a">
            <div @click="inS(1)">
              <p>消息</p>
            </div>
            <div @click="inS(2)">
              <p>关注</p>
            </div>
            <div @click="inS(3)">
              <p>谁看过我</p>
            </div>
          </div>
        </div>
        <div class="btn2" @mouseout="out(2)" @mouseover="over(2)">
          <img src="../../assets/images/index/me.png" alt="" />
          <div class="btn-box2" ref="box1" v-show="b">
            <div>
              <div class="examine" @click="insurance(1)">编辑资料</div>
            </div>
            <div>
              <div class="examine" @click="insurance(2)">诚信认证</div>
            </div>
            <div>
              <div class="examine" @click="insurance(3)">个人相册</div>
            </div>
            <div>
              <div class="examine" @click="insurance(4)">系统设置</div>
            </div>
            <div>
              <div class="examine box" @click="insurance(5)">退出</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="page">
      <img src="../../assets/images/tubiao/yezai_logo_B.png" alt="" />
      <ul>
        <li :class="$store.state.index == 0 ? 'active' : ''">
          <router-link to="/n/myyezai">我的也在</router-link>
        </li>
        <li :class="$store.state.index == 1 ? 'active' : ''">
          <router-link to="/n/search">搜索</router-link>
        </li>
        <li>
          <p @click="tiao">直营门店</p>
        </li>
        <li :class="$store.state.index == 2 ? 'active' : ''">
          <router-link to="/n/yezaiMember">也在会员</router-link>
        </li>
      </ul>
      <div class="h-btn">
        <div class="btn1" @mouseout="out(1)" @mouseover="over(1)">
          <img src="../../assets/images/index/xiaoxi.png" alt="" />
          <div class="btn-box1" ref="box1" v-show="a">
            <div @click="inS(1)">
              <p>消息</p>
            </div>
            <div @click="inS(2)">
              <p>关注</p>
            </div>
            <div @click="inS(3)">
              <p>谁看过我</p>
            </div>
          </div>
        </div>
        <div class="btn2" @mouseout="out(2)" @mouseover="over(2)">
          <img src="../../assets/images/index/me.png" alt="" />
          <div class="btn-box2" ref="box1" v-show="b">
            <div>
              <div class="examine" @click="insurance(1)">编辑资料</div>
            </div>
            <div>
              <div class="examine" @click="insurance(2)">诚信认证</div>
            </div>
            <div>
              <div class="examine" @click="insurance(3)">个人相册</div>
            </div>
            <div>
              <div class="examine" @click="insurance(4)">系统设置</div>
            </div>
            <div>
              <div class="examine box" @click="insurance(5)">退出</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import local from "@/api/common/local.js";
import {logout} from "@/api/my/my.js";
export default {
  data() {
    return {
      a: false,
      b: false,
      index: 0,
    };
  },
  created() {
    // this.hun();
  },
  methods: {
    // hun() {
    //   //   if(to.fullPath == '/n/myyezai'){
    //   //   this.idnex = 0
    //   // }else if(to.fullPath == '/n/search'){
    //   //   this.index = 1
    //   // }else if(to.fullPath == '/n/yezaiMember'){
    //   //   this.index = 2
    //   // }else{
    //   //   this.index = 0
    //   // }
    //   // console.log('数据',this.$route.path);
    //   console.log("数据", this.$route.path, this.$store.state.index);
    //   if (this.$route.path == "/n/myyezai") {
    //     // this.idnex = 0;
    //     this.$store.state.index = 0;
    //   } else if (this.$route.path == "/n/search") {
    //     // this.index = 1;
    //     this.$store.state.index = 1;
    //   } else if (this.$route.path == "/n/yezaiMember") {
    //     this.$store.state.index = 2;
    //     // this.index = 2;
    //   }
    // },
    //路由跳转
    async insurance(id) {
      //直接调用$router.push 实现携带参数的跳转
      if (id == 1) {
        let rel = this.$router.resolve({
          path: "/n/user/_baseInfo/left",
          query: {
            id: id,
          },
        });
        window.open(rel.href, "_blank");
        this.$store.state.infoId = 0
      } else if (id == 2) {
        let rel = this.$router.resolve({
          path: "/n/user/_auth",
          query: {
            id: id,
          },
        });
        window.open(rel.href, "_blank");
        this.$store.state.infoId = 7
      } else if (id == 3) {
        let rel = this.$router.resolve({
          path: "/n/user/_album",
          query: {
            id: id,
          },
        });
        window.open(rel.href, "_blank");
        this.$store.state.infoId = 8
      } else if (id == 4) {
        let rel = this.$router.resolve({
          path: "/n/user/_setting/left",
          query: {
            id: id,
          },
        });
        window.open(rel.href, "_blank");
        this.$store.state.infoId = 9
      } else if (id == 5) {
        local.remove("access_token");
        local.remove("user");
        local.remove("Date");
        local.remove("iSza");
        local.clear()
        await logout();
        this.$router.push({
          path: `/login`,
        });
      }
    },
    inS(id) {
      //直接调用$router.push 实现携带参数的跳转
      if (id == 1) {
        let rel = this.$router.resolve({
          path: "/n/message",
        });
        window.open(rel.href, "_blank");
      } else if (id == 2) {
        let rel = this.$router.resolve({
          path: "/n/message/_visit",
        });
        window.open(rel.href, "_blank");
      } else if (id == 3) {
        let rel = this.$router.resolve({
          path: "/n/message/_follow",
        });
        window.open(rel.href, "_blank");
      }
    },
    //移入移出
    over(num) {
      if (num == 1) {
        this.a = true;
      } else if (num == 2) {
        this.b = true;
      }
    },
    out(num) {
      if (num == 1) {
        this.a = false;
      } else if (num == 2) {
        this.b = false;
      }
    },

    //跳转新页面
    tiao() {
      window.open(
        this.$router.resolve({
          path: "/directStore/home",
        }).href,
        "_blank"
      );
    },
  },
  // watch: {
  //   $route(to, from) {
  //     // console.log('路由' ,to.fullPath);
  //     console.log("路由", from);
  //     if (to.fullPath) {
  //       // this.index = 0;
  //       // this.index = 0;
  //       this.$store.state.index = 0;
  //     }
  //     if (to.fullPath == "/n/myyezai") {
  //       // this.idnex = 0;
  //       this.idnex = 0;
  //       this.$store.state.index = 0;
  //     } else if (to.fullPath == "/n/search") {
  //       // this.index = 1;
  //       // this.index = 1;
  //       this.$store.state.index = 1;
  //     } else if (to.fullPath == "/n/yezaiMember") {
  //       // this.index = 2;
  //       this.$store.state.index = 2
  //     } else {
  //       // console.log('数据有哟哟哟哟哟哟');
  //       // this.index = 0;
  //     }
  //   },
  // },
};
</script>

<style lang="less" scoped>
.header,
html,
body {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fd686e;
  position: fixed;
  z-index: 2999;
  .page {
    width: 1200px;
    height: 80px;
    background-color: #fd686e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul {
      width: 770px;
      display: flex;
      align-items: center;
      li {
        // margin: 0px 50px;
        margin: 0px 50px 0px 0px;
        position: relative;
        a {
          color: #ffffff;
          font-size: 16px;
        }
        p {
          color: #ffffff;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .active::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -18px;
        width: 30px;
        height: 3px;
        background: white;
        border-radius: 2px;
      }
    }
    .h-btn {
      display: flex;
      position: relative;
      img {
        cursor: pointer;
      }
      .btn1 {
        width: 60px;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-box1 {
          position: absolute;
          top: 65px;
          right: 60px;
          padding: 10px 10px 0 20px;
          width: 160px;
          height: 134px;
          background: white;
          box-shadow: 0px 2px 8px 0px rgba(202, 202, 202, 0.5);
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          div {
            width: 160px;
            height: 40px;
            display: flex;
            align-items: center;
            cursor: pointer;
            a {
              color: #333;
            }
            a:hover {
              color: #333;
            }
          }
        }
        .btn-box1::after {
          content: "";
          position: absolute;
          top: -20px;
          right: 20px;
          box-sizing: border-box;
          width: 10px;
          height: 0;
          border: 10px solid transparent;
          border-bottom: 10px solid #fff;
        }
      }
      .btn2 {
        width: 60px;
        // height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-box2 {
          position: absolute;
          top: 65px;
          right: 0px;
          padding: 10px 10px 0 20px;
          // margin: 10px 10px 0 20px;
          width: 160px;
          // height: 134px;
          background: white;
          box-shadow: 0px 2px 8px 0px rgba(202, 202, 202, 0.5);
          border-radius: 4px;
          div {
            width: 160px;
            height: 40px;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          .box {
            border-top: 1px solid #0000001f;
          }
        }
        .btn-box2::after {
          content: "";
          position: absolute;
          top: -20px;
          // right: 12px;
          right: 18px;
          box-sizing: border-box;
          width: 10px;
          height: 0;
          border: 10px solid transparent;
          border-bottom: 10px solid #fff;
        }
      }
    }
  }
}
</style>
